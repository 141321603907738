.dns-container {

    display: flex;
    min-height: calc(100vh - 210px);
    border-radius: 10px;
    box-shadow: 0px 2px 6px #0000001a;
    background-color: white;
    flex-direction: column;
    padding:20px;
    justify-content: flex-start;
}
.input-field {
    padding: 10px;
    font-size: 1em;
    text-align: center;
}

.check-button {
    padding: 0.5em 1em;
    font-size: 1em;
    cursor: pointer;
}

.dns-search{border: solid 2px var(--primary)}
.dns-table {
    margin-top: 1em;
    border-collapse: collapse;
    padding: 0 20px;
}

.table-header, .table-cell {
    border: 1px solid #ddd;
    padding: 8px;
}

.table-header {
    background-color: #f2f2f2;
}

#search-container {
    padding: 20px
}

#search-container > input {
    font-size: 1rem;
    padding-left: 10px;
    text-align: center;
}

.buttons-container-dns > button{
padding: 10px 20px;
    margin: 0;
    cursor: pointer;
}

.buttons-container-dns {
    padding: 0 20px;
}

.export-button:hover {
    background-color: var(--forth);
    transition: background-color .3s;
}


