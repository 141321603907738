@import url('https://fonts.cdnfonts.com/css/mona-sans');


:root {
    --primary: #1dbab0;
    --secondary: rgb(59, 173, 223);
    --third: rgb(53, 54, 94);
    --forth: #dedede;
    --left-header-size: 400px;
    --small-width-navbar: 100px;
}

.App {
    /*text-align: center; ???????*/
}

.navbar-sub-title > ul {
    list-style-type: none !important
}

h2 {
    text-align: center
}

.navbar-title > h2 {
    text-align: unset;
}
.btn-active {
    background-color: var(--secondary)!important;
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.box-shadow-none {
    box-shadow: none !important;
}

.border-radius-10 {
    border-radius: 10px !important;
}

.border-radius-5 {
    border-radius: 5px !important;
}

.box-shadow {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
}

.box-shadow-2px-top {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;
}

.bg-white {
    background-color: white !important;
}

.p-0 {
    padding: 0 !important;
}

.App-header {
    background-color: #eee;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.rc-slider-handle {
    z-index: unset !important;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body {
    font-family: "Montserrat", sans-serif !important;
}

.navbar-title:first-child {
    position: fixed;
    width: 400px;
    background: white;
    z-index: 99999999;
    height: 110px;
    top: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
    left: 0;
}

.title-first {
    margin-top: 120px;
}

.navbar-title {
    margin-bottom: 40px;

    h2 {
        font-weight: 700;
        margin: 10px;
        font-size: 18px;
    }

    h3 {
        font-weight: 600!important;
        margin: 10px;
        font-size: 16px;
    }

    h4 {
        font-weight: 500;
        margin: 10px;
        font-size: 14px;
    }

    ul {
        list-style-type: none;

    }
}

.text-primary {
    color: var(--primary) !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

.text-black {
    color: black !important;
}

.bg-primary {
    background-color: var(--primary);
}
.bg-secondary {
    background-color: var(--secondary);
}

.input-container.department, .input-container.ticket-type{
    z-index: 11!important;
}

.justify-content-space-between > .customer-ticket {
    z-index: 9;
}
.justify-content-space-between > .technique {
    z-index: 8;
}

.justify-content-space-between > .department {
    z-index: 2;
}

body {
    margin: 0 !important;
    font-family: 'Montserrat', sans-serif !important;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top-bar {
    height: auto;
    top: 0;
    position: relative;
}

body {
    margin: 0 !important;

    background-color: white !important;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

button {
    font-size: .9rem;
    font-weight: 700;
    border: none;
    border-radius: 3px;
    padding: .3rem 1rem;
}

.button-default {
    background: #247BA0;
    color: #fff;
}

.td-cell {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.header-cell {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.title-cell {
    background-color: #e6f7ff;
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    font-weight: bold;
}

.index-cell {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 8px;
}

.round-button {
    box-shadow: 0 0 20px rgb(0, 0, 0, .2);
    border: solid white 2px;
    display: flex;
    margin: auto;
    text-align: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    align-items: center;justify-content: center;
    font-weight: 700;
}

.logo-sd {
    height: 50px;
    padding: 30px
}

.navbar-container {
    width: 100%;
    left: 0;
    text-align: left;
    z-index: 99;
    background-color: white;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.15) 0 9px 18px 0;
}

.mobile-navbar-hidden {
    display: none;
}

.mobile-navbar > .navbar-container {
    display: grid;
    position: fixed;
    overflow-y: auto;
}

.container-position-buttons-time > div:first-child {
    width: 380px;
}

.inline-buttons {
    flex-direction: column;
    width: 380px;
}

@media screen and (max-width: 734px) {

    .container-save-btn {
        padding-top: 0 !important;
        margin-bottom: 15px;
    }

}
@media screen and (max-width: 980px) {

    .container-position-buttons-time > div:first-child {
        width: 100%;
    }

    .inline-buttons > * {
        flex:1;
    }

    .container-position-buttons-time {
        flex-direction: column;
    }

    .container-position-buttons-time > div {
        width: 100%;
        flex-direction: row;
    }

    .inline-buttons {
        flex-direction: row;
        width: 100%;
    }

}

@media screen and (max-width: 600px) {

    .inline-buttons {
        flex-direction: column!important;
        width: 100%;
    }

    .timings-container{
        flex-direction: column!important;
    }

    .searchbar-results{

        width: -webkit-fill-available;
        margin: 10px 20px!important;
    }

}
@media screen and (max-width: 1200px) and (min-width: 601px) {

    .searchbar-results{
        min-width: 430px!important;
        margin-left: 100px!important;
    }
}
@media screen and (max-width: 1200px) {


    .logo-image {
        height: 80px!important;
        width: auto;
    }

    .login-form input{
        font-size: .9rem!important;
    }

    .flex-direction-row-1200 {
        flex-direction: row!important;
    }
    .flex-direction-column-1200 {
        flex-direction: column!important;
    }

    .main-searchbar-container-contained {
        width: 100vw !important;
        padding: 20px 0 !important;
        z-index: 1;
        left: 0 !important;
    }

    .search-container {
        display: flex;
        padding: 0 20px 0 100px;
    }

    .navbar-container {
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-color: white;
        z-index: 9;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        transition: height 5s ease-in-out!important;
    }

    .navbar-container {
        transition: height 1s ease-in-out;
    }

    .navbar-main-container {
        margin-top: 200px;
    }

    .mobile-navbar-hidden > .navbar-container {
        height: 0;
        transition: height 1s ease-in-out!important;
    }
    .mobile-navbar-visible > .navbar-container {
        height: 100vh;
        transition: height 1s ease-in-out!important;
    }

    .navbar-title:first-child {
        width: 100vw !important;
    }
}

.navbar-main-container {
    padding: 20px 20px 0 20px;
    overflow-y: auto;
}

.main-container-composant {
    padding: 20px;
    background: #f8f8f8;
    border-top-left-radius: 15px;
    border-top-left-radius: 15px 15px;
    box-shadow: inset 0 0 10px #00000026;
    margin-left: 0;
    margin-top: 80px;
    min-height: calc(100vh - 80px);
}
.main-container-composant > div > div {
    padding:20px
}

     .main-container-composant > div {
    /*padding: 20px; /* Optional padding */
    border-radius: 7.5px;
    height: calc(100vh - 120px);
    overflow-y: scroll;
}

.ql-container{height: unset!important;}

.main-container-composant > div:before {
    content: ''; /* Empty content for the pseudo-element */
    position: sticky; /* Keeps the shadow effect in place at the top */
    top: 0; /* Positioned at the top of the container */
    left: 0;
    width: 100%; /* Full width */
    height: 10px; /* Height of the shadow effect */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), transparent); /* Fading shadow effect */
    z-index: 1; /* Ensure it appears above the content */
    pointer-events: none; /* Prevent interaction with the pseudo-element */
}

.main-container-composant > div > h2 {
    margin-top: 0;
}

.orangered-21pts {
    color: orangered;
    font-size: 21px;
}

.position-icon {
    padding: 20px;
    height: 100% !important;
}

.background-primary {
    background-color: var(--primary) !important;
}

.rounded {
    border-radius: 9999px;
}

.color-primary {
    color: var(--primary)
}

.color-third {
    color: var(--third) !important;
}

.position-icon-container {
    min-width: 100px;
    display: flex;
    justify-content: center;
    height: 100%;
}

.container {
    border-radius: 5px;
    background-color: white;
    padding: 20px;
}

.toggle-container {
    width: 100%;
    display: block;
    justify-content: center;
}

.toggle-button {
    cursor: pointer;
    padding: 10px;
    background-color: var(--primary);
    font-weight: 600;
    color: white;
}

.hidden-content {
    display: none;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.3s ease-out; /* Smooth animation */
}

.hidden-content.show {
    display: block;
}

.hidden-content > table {
}

.button-action-save {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    color: black;
    background-color: #61dafb;
}

.button-action-save:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    color: black;
    background-color: #41cdf3;
}

.button-action:hover {
    background-color: grey;
}


.button-action.delete {
    background-color: orangered;
    color: white;
}

.button-action.delete:hover {
    color: white;
    background-color: #e82424;
}
.button-action.save {
    background-color: #03a1d5!important;
    color: white;
}

.button-action.save:hover {
    color: white;
    background-color: #038bb7!important;
}

.button-action.create-task {
    color: white;
    background-color: rgb(72, 159, 68);
}

.button-action.create-task:hover {
    color: white;
    background-color: #329430;
}
.button-action.see-customer {
    color: white;
    background-color: var(--third);
}

.button-action.see-customer:hover {
    color: white;
    background-color: rgb(37, 37, 65);
}

.button-action.end-project:hover {
    color: white;
    background-color: cornflowerblue;
}

.border-none {
    border: none !important;
}

.nav-link:hover {
    background-color: var(--primary);
    border-radius: 10px;

    cursor: pointer;
    color: white;
}

.nav-link > a:hover {
}

h3 {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 18px !important;
}

h3.color-third {
    position: relative;
}

.grid-navbar > a {
    text-decoration: none
}

.nav-link {
    text-decoration: none !important;
    font-size: 16px !important;
    font-family: 'Montserrat', sans-serif !important;
    margin: 0 !important;
    padding: 9px 0 9px 10px
}


/* Menus navbar, liens vers les pages */
.navbar-title > div > ul > li > ul > a {
    text-decoration: none !important;

}

.navbar-title > div > ul > li > ul > a:hover {
    color: white;
}

.user-columns {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
}

.user-column {
    flex: 1 0 25%; /* Each column takes up 25% width */
    max-width: 25%; /* Maximum width of each column */
    padding: 0 10px; /* Add spacing between columns */
}

.user-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Adjust width and height as needed */
    width: 100%;
    box-sizing: border-box;
    background-color: white;
}

.user-item:hover {
    background-color: #f5f5f5;
}

.username {
    font-weight: bold;
}

.user-details {
    margin-top: 5px;
    font-size: 0.9em;
}

.email {
    color: #666;
}

.role {
    margin-left: 10px;
    padding: 3px 6px;
    background-color: #f0f0f0;
    border-radius: 3px;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pt-10 {
    padding-top: 10px !important;
}
.pr-10 {
    padding-right: 10px !important;
}

.text-white{
    color: white!important;
}

.bg-warning {
    background-color: #bf7c02!important;
}

.users-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr)); /* Each column is at least 50% width */
    grid-gap: 10px; /* Adjust spacing between items as needed */
}

.user-pair {
    display: flex;
}

.user-left,
.user-right {
    flex: 1; /* Each user takes up equal space within the pair */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Media query for responsive layout */
@media (max-width: 768px) {
    .users-grid {
        grid-template-columns: 100%; /* On smaller screens, display users in a single column */
    }

    .user-left,
    .user-right {
        width: 100%; /* Full width for each user on smaller screens */
    }
}


@media (max-width: 1350px) {
    .container-1400-col{
        flex-direction: column;
    }
}

.selected {
    background-color: var(--primary);
}

hr {
    margin: 0 !important;
    border: 1px solid #dfdfdf
}

.cursor-pointer {
    cursor: pointer;
}

.task-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 0px !important;
    margin-top: 10px;
    border: unset !important;
}

.show > .task-table {
    margin-bottom: 20px;
}

.task-table th,
.task-table td {
    padding: 8px;
}

.task-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.task-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.task-table tbody tr:hover {
    background-color: #e2e2e2;
}

tbody.email {
    background-color: #ffffff !important;
}

.progress-td {

    width: 110px;
    padding: 20px 30px 20px 30px !important;
}

.position-container > hr {
    margin: 30px 0 30px 0 !important;

}

.position-container-overflow {
    max-height: calc(100vh - 380px);
    overflow: auto;
    border-radius: 10px;
}

.overflow-auto {
    overflow: auto !important;
}

.task-table {

}

.position-container {
    height: auto;
}

th#Progression {
    max-width: 100px !important;
}

.input-time {
    padding-left: 0 !important;
}

.position-container.right {
    display: flex;
    flex-direction: column;
}

.position-container > div > h3 {
    margin-top: 0;
}

.position-container.left {
    text-align: left;
    padding-right: 15px;
    flex: 3;
}

.position-left {

}

.position-container.right {
    text-align: center;
    flex: 8;
}

.flex {
    flex: auto;
}

.border-color-ddd {
    border-color: #ddd !important;
}
.color-ddd {
    color: #ddd!important
}
.border-color-ccc {
    border-color: #ccc !important;
}
.color-ccc {
    color: #ccc!important
}
.color-aaa {
    color: #aaa!important
}
.border-color-aaa {
    border-color: #aaa !important;
}
.color-ccc {
    color: #ccc!important
}
.border-solid-1px-top {
    border-top: solid 1px;
}

.h-100 {
    height: 100% !important;
}


.position-note-textarea {
    font-family: "Montserrat", sans-serif;
    min-height: 120px;
    padding: 10px 14px;
    border: 1px solid #ddd;
    border-radius: 6px;
    resize: both;
    background-color: #fefefe;
    outline: none;
    transition: background-color 0.2s ease;
    font-size: 1rem;
}

.ml-20 {
    margin-left: 20px !important;
}

.position-note-textarea:focus {
    outline: none; /* Remove default focus outline */
    border: 1px solid var(--primary);
    background-color: #eeee;
}

.client-infos-bg {

    background-color: #F9FAFB !important;
}

.border-221-1px {
    border: 1px solid rgb(221, 221, 221) !important;

}

.position-component {
    background-color: white;
}

.bg-unset{
    background-color: unset!important;
}

.border-bottom-none {
    border-bottom: none !important;
}

.button-action {
    padding: 10px 15px;
    cursor: pointer;
    background-color: white;
}

.client-compo-left {
    border-right: solid 1px #dedede;
}

.table-search > tbody > tr:hover {
    background-color: #61dafb;
}

.bg-third {
    background-color: var(--third) !important;
}
.bg-orangered {
    background-color: orangered !important;
}

.styled-table {
    width: 100%
}

.display-grid {
    display: grid;
}

.display-flex {
    display: flex;
}

.color-red{color:red!important;}

.tableFixHead {
    overflow: auto;
}

.tableFixHead thead th {
    position: sticky;
    z-index: 0;
    top: -1px;
}

.select-project {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.positions-list {
    display: grid;
}

.positions-list-item {
    background-color: rgba(239, 239, 239, 0.48);
}

.finished-projects:not(:last-child) {
    margin-bottom: 7.5px;
}

.positions-list-item:hover {
    background-color: #EFEFEF;
    transition: background-color .3s ease;
}

.px-10 {
    padding: 0 10px !important;
}

.h3-navbar {
    top: -3px
}

.hover-lightgray:hover {
    background-color: #dedede;
    transition: background-color .3s;
}

.search-bar {
    width: auto;
    background-color: var(--primary);
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 0 20px rgb(0, 0, 0, .2);
}

.no-radius-bottom {
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
}

.no-radius-top {
    border-top-right-radius: unset !important;
    border-top-left-radius: unset !important;
}

.p-20 {
    padding: 20px !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.p-15 {
    padding: 15px !important;
}

.client-infos-container {
    box-shadow: 0 4px 6px #0000001a;
    background-color: #F9FAFB !important;
    border-radius: 0 10px 10px;
}

.bg-color-tech {
    background-color: #35365E;
    color: white !important;
}

.bg-color-compta {
    background-color: #efefef;
}

.bg-color-prod {
    background-color: #1DBAB0;
}

.bg-color-vente {
    background-color: #000;
    color: white !important;
}

.bg-color-admin {
    background-color: #3BADDF;
}

.radius-bottom-right-10 {
    border-bottom-right-radius: 10px !important;
}

.client-compo-right {
    background-color: white;
}

.bg-fourth {
    background-color: var(--forth);
}

.p-10 {
    padding: 10px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.bg-ddd{
    background-color: #ddd!important;
}

.pt-5 {
    padding-top: 5px !important;
}

.p-5 {
    padding: 5px !important;
}


.search-form {
    width: 100%;
    height: 40px;
    border: 0;
    font-family: "Montserrat", sans-serif;
    border-radius: 5px;
}

.table-search {
    box-shadow: 0 0 5px rgb(0, 0, 0, .2);
    border-collapse: collapse;
    width: 100% !important;
}

input, select {
    font-family: "Montserrat", sans-serif
}

/* la */

.login-container {
    display: flex;
    align-items: center; /* Center items vertically */
    height: auto;
    justify-content: center; /* Center items horizontally */
}

.register-container .input-container select {
    display: flex;
    align-items: center; /* Center items vertically */
    height: auto;
    justify-content: center; /* Center items horizontally */
    font-size: 1rem !important;
}

.account-container {
    display: flex;
    height: calc(100vh - 150px);
    border-radius: 10px;
    box-shadow: 0 0 10px #eee;
    background-color: white;
    flex-direction: column;
    justify-content: flex-start; /* Center items horizontally */
}

.login-form {
    padding: 30px;
    border-radius: 8px;
    width: 600px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */

}

.register-form {

    background-color: white;
    padding: 30px;
    border-radius: 8px;
    max-width: 700px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70vw;
}

.ticket-form {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 1200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70vw;
}

.register-form input, .register-form select {
    width: 100%;
    padding: 20px;
    margin: 15px 0 15px;
    border: 1px solid #ccc;
    border-radius: 10px !important;
    box-sizing: border-box;
    height: 60px;
    font-weight: 500;
    font-size: 1rem;
}

th#Durée {
    min-width: 90px;
}

input.input-time {
    flex: unset !important;
}

.dropdown-search {

    position: absolute;
    background: var(--primary);
    z-index: 1;
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.h1-position-title {
    margin-top: 0
}

.dropdown-search > li {
    padding: 10px;
}

.dropdown-search > li:hover {
    background-color: var(--secondary);
    cursor: pointer;
    color: white;
    transition: background-color .1s, color .1s;
}

.account-form {
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start; /* Center items vertically */
}

.title {
    margin-bottom: 20px;
    color: var(--third);
}

.login-form input {
    width: 100%;
    padding: 20px;
    margin: 15px 0 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 60px;
    font-weight: 500;
    font-size: 1.15rem;
}

.btn-login {
    background-color: var(--primary);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 20px 0 0 0;
    height: 60px;
    font-size: 1.15rem;
}

.btn-login:hover {
    background-color: var(--secondary);
    transition: background-color .3s;
}

.btn:hover {
    background-color: #0056b3;
}

.loading-img {
    height: 50px;
}

.logo-image {
    height: 150px;
    width: auto;
}

.image-container {
    margin-bottom: 20px; /* Adjust margin as needed */
}

.input-container {
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 20px;
}

.register-form > .input-container > select {
    height: 60px;
    width: 100%;
    padding: 20px;
    margin: 15px 0 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 1rem;
}

.input-container > select {
    height: 60px;
    width: 100%;
    padding: 20px;
    margin: 15px 0 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 1.15rem;
}

.input-icon {
    padding: 0 20px 0 0px;
    width: 30px;
    color: var(--primary);
    font-size: 1.25rem;
}

.input {
    flex: 1;
    border: none;
    padding: 10px;
}

.icon {
    color: #888;
}

.btn-generate-pw {
    height: 60px;
    width: 60px;
    cursor: pointer;
}

.btn-generate-pw:hover {
    background-color: var(--secondary);
    transition: background-color .3s;
}

input:focus-visible, select:focus-visible {
    outline: var(--third) auto 1px;
    /*box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);*/
}

.hidden {
    display: none;
}

.pagination button {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #333;
    cursor: pointer;
    padding: 6px 12px;
    margin-right: 5px;
    border-radius: 4px;
}

.pagination button.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

.pagination button:hover {
    background-color: #f0f0f0;
}

.footer-container {
    justify-content: space-evenly;
    grid-template-columns: 100px 50px 100px;
    grid-template-rows: 80px auto 80px;
    column-gap: 10px;
    row-gap: 15px;
    display: flex;
    font-size: 1.5rem;
    padding: 20px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
}

.btn-generate {

    color: var(--primary);
    height: 40px;
    width: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 10px;
}

.btn-generate:hover {

    color: white;
    background-color: var(--primary);
    transition: background-color .3s, color .3s;
}

.position-id-text {
    text-align: center;
    color: var(--primary);
    font-weight: 700;
}

th#\% {
    text-align: center !important;
}

.pagination {
    background-color: var(--primary);
    border-radius: 5px 5px 0 0;
    padding: 20px;
    box-shadow: 0 0 20px rgb(0, 0, 0, .2);
    display: flex;
    justify-content: center;
}

.align-items-center {
    align-items: center
}

.align-items-flex-end {
    align-items: flex-end !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.my-10 {
    margin: 10px 0 !important;
}

.my-15 {
    margin: 15px 0 !important;
}

.mb-50 {
    margin-bottom: 50px
}

h2 {
    color: var(--secondary);
}

.bg-eee {
    background-color: #eee;
}

.max-w-400 {
    max-width: 400px
}

.min-w-400 {
    min-width: 400px
}

.min-w-200 {
    min-width: 200px
}

h3 {
    color: var(--primary);
    margin-top: 0 !important;
}

h4 {
    color: black;
}

.display-block {
    display: block !important;
}

#search {
    font-size: 1.15rem;
    padding-left: 10px;
}

.card {
    flex: 1;
    min-height: 250px;
    background-color: var(--primary);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 20px rgb(0, 0, 0, .2);
}

.last-tasks-container {
    flex-direction: column;
    display: flex;
    justify-content: center;
}

.last-tasks-container > h3 {
    color: #bff2ff;
}

.input-time {
    font-family: "Montserrat", sans-serif;
    height: 40px;
    text-align: center;
    width: 80%;
    align-items: center;
}

.input-time-container {

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.c3-chart-line {
    stroke-width: 5px !important;
}

g > g.c3-shapes.c3-shapes-Montant.c3-areas.c3-areas-Montant > path {

    fill: var(--secondary) !important;
}

g.c3-shapes.c3-shapes-Montant.c3-lines.c3-lines-Montant > path {
    stroke: var(--primary) !important;
}

.reporting-container {
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-options {
    display: inline-grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
}

.pl-0 {
    padding-left: 0 !important;
}

.filter-label {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}

.list-style-type-none {
    list-style-type: none !important;
}

.filter-label:hover {
    transform: translateY(-3px);
}

.filter-label input[type="radio"] {
    margin-right: 10px;
    cursor: pointer;
}

/* Colors for different filters */
.last60Days {
    background-color: #5a9ef9;
    color: #ffffff;
}

.last3Months {
    background-color: #66bb6a;
    color: #ffffff;
}

.last6Months {
    background-color: #f2a654;
    color: #ffffff;
}

.lastYear {
    background-color: #ec7f7f;
    color: #ffffff;
}

.text-label-reporting {
    color: var(--primary) !important;
    text-align: left;
}

.wip {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.icon-info-client {
    width: 40px;
    display: inline-block;
}

.content {
    background-color: white !important;
    border-radius: 15px;
    padding: 20px;
}

.m-0 {
    margin: 0 !important;
}

.icon-filter {
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.content.c3 {
    height: unset !important;
    max-height: unset !important;
}

.shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-5 {
    margin-top: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.my-20 {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-0 {
    margin-left: 0px !important;
}
.button-action.shadow{border-radius: 5px}
.button-action.shadow:hover {
    background-color: var(--primary) !important;
    transition: background-color .3s;
}

.c3 > svg {
    width: 100% !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.container-seo {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.search-item-found {
    padding: 10px
}

.search-item-found:hover {
    color: black;
    border-radius: 5px;
    background-color: #efefef;
}

.copied-message {
    color: green;
    margin-top: 10px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.copied-message.visible {
    opacity: 1;
}

.d-none {
    display: none;
}

.seo-search-container {
    flex-direction: row;
    flex-wrap: nowrap;
}

.search-bar-seo {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    width: 100% !important;
    padding-left: 10px !important;
}

.seo-search-container > label {
    width: 100%;
    flex: 2;
    margin-right: 10px;
}

.btn-copy {
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.m-5 {
    margin: 5px
}

.m-10 {
    margin: 10px
}

.mr-0{margin-right: 0!important;}

.m-20 {
    margin: 20px
}

.m-50 {
    margin: 50px
}

.btn-generate-meta {
    cursor: pointer;
    flex: 1;
    height: 38px !important;
    background-color: white;
    padding: 10px 15px
}

.btn-custom-meta {
    flex: 1;
    background-color: white;
    padding: 10px 15px;
    height: 16px;
    border-radius: 3px;
    cursor: unset !important;
}

.btn-generate-meta:hover {
    color: black;
    background-color: var(--forth);
    transition: background-color .3s;
}

.icon-eye, .icon-check, .icon-trash {
    font-size: 1.25rem;
    cursor: pointer;
    text-align: center;
}

.icon-eye {
    color: cornflowerblue
}

.icon-check {
    color: green;
}

.icon-trash {
    color: red;
}

.positions-found {
    text-align: left;
    padding-left: 15px;
    font-style: italic
}

.pl-10 {
    padding-left: 10px!important;
}

.center-block {
    text-align: center;
    display: block
}

.t-0 {
    top: 0 !important;
}

.d-ib {
    display: inline-block
}

.text-decoration-none {
    text-decoration: none !important;
}

button {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;

}

.margin-0 {
    margin: 0
}

.flex-1 {
    flex: 1 !important;
}

.flex-2 {
    flex: 2 !important;
}

.flex-3 {
    flex: 3 !important;
}

.flex-4 {
    flex: 4 !important;
}

.flex-5 {
    flex: 5 !important;
}

.color-white {
    color: white !important;
}

.position-relative {
    position: relative !important;
}

.selected-tasks-options {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.selected-tasks-options > button {
    font-size: 16px;
    padding: 10px;
    margin: 15px;
    flex: 1;
    height: 40px;
}

.selected-tasks-options > button:first-child {
    margin-left: 0; /* Set left margin to 0 for the first button */
}

.selected-tasks-options > input {
    height: 40px;
    padding: 10px;
    background-color: rgb(246, 248, 250);
    border-radius: 5px;
    border: none;
    font-family: "Montserrat", sans-serif;
    font-size: 16px
}

.select-assign {
    padding: 10px 5px;
    font-family: "Montserrat", sans-serif;
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
}


.input {
    width: 100%;
    padding: 10px;
    padding-left: 35px; /* Add space for the icon */
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none; /* Remove default dropdown arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    background-image: none; /* Remove default dropdown arrow for older browsers */
}

.custom-select::after {
    content: '▼'; /* Unicode character for down arrow */
    position: absolute;
    top: 50%;
    right: 25px; /* Adjust right position as needed */
    transform: translateY(-50%);
    pointer-events: none; /* Allow clicks to go through the custom arrow */
    font-size: 12px; /* Adjust font size as needed */
    color: #27BEB4; /* Adjust color as needed */
}

.border-bottom-1px-third {
    border-bottom: 1px solid var(--third);
}
.border-bottom-1px-ccc {
    border-bottom: 1px solid #ccc;
}

.pt-20{
    padding-top: 20px!important;
}

.h-50{
    height: 50%!important;
}

.no-outline {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    background-color: unset;
}

.no-outline:focus-visible {
    outline: none !important;
    border: none !important;
    box-shadow: unset;
}

.position-note-textarea:hover {
    background-color: #ddd;
}

.no-border {
    border: none !important;
}

.position-absolute {
    position: absolute !important;
}

.container-home-1 {
    height: 100vh !important;
    display: flex !important;
    flex-direction: row !important;
    align-content: center !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right;
}

.font-bold {
    font-weight: bold !important;
}

.icon-footer {
    color: var(--primary)
}

.chart-container {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.download-btn {
    border-radius: 10px;
    padding: 15px 20px;
    background-color: var(--third);
    width: auto;
    display: inline-flex;
    color: white;
    box-shadow: 0 0 10px #999;
}

.download-btn:hover {
    background-color: var(--secondary);
    color: var(--third);
    transition: color .3s, background-color .3s ease-out;
}

.download-btn-inverted {
    border-radius: 10px;
    padding: 15px 20px;
    background-color: var(--secondary);
    width: auto;
    display: inline-flex;

    color: var(--third);
    box-shadow: 0 0 10px #999;
}

.download-btn-inverted:hover {
    background-color: var(--third);
    color: white;
    transition: color .3s, background-color .3s ease-out;
}

.footer {
    padding: 20px
}

.text-center {
    text-align: center !important;
}

.index-cell > a {
    text-decoration: none
}

.index-cell > a:visited {
    color: var(--primary)
}

.align-items-baseline {
    align-items: baseline !important;
}


.flex-direction-row-reverse {
    flex-direction: row-reverse;
}

.flex-direction-row {
    flex-direction: row;
}

.flex-direction-column {
    flex-direction: column
}

.min-h-400{
    min-height: 400px;
}

.gap-5 {
    gap: 5px
}

.gap-10 {
    gap: 10px
}

.gap-15 {
    gap: 15px
}

.gap-20 {
    gap: 20px
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.active {
    font-weight: bold;
    color: #007bff; /* Change to the color you want */
}

.top-0 {
    top: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.small {
    /* Styles for the small version of the navbar */
    /* For example, to hide the navbar: */
    width: var(--small-width-navbar);
}

/* la */

/* Default Expanded Navbar */
.navbar-expanded {
    width: 380px;
    position: fixed;
    transition: width 0.3s ease-in-out;
    overflow-y: auto;
}

/* Collapsed Navbar */
.navbar-collapsed {
    width: 80px;
    position: fixed;
    transition: width 0.3s ease-in-out;
    overflow: hidden;
}

/* Hamburger Button */
.toggle-button-navbar {
    display: none;
    font-size: 24px;
    cursor: pointer;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
    height: 80px!important;
    padding: 0 30px!important;
    display: flex;
    align-items: center;
}


/* la */

.navbar-expanded {
    width: 380px;
    position: fixed;
}

.navbar-collapsed {
    display: none;
}

.main-content {
    background-color: white;
    position: absolute;
}

/* Detect scrollbar width */
:root {
    --scrollbar-width: 0px; /* Default fallback */
}

.w-100 {
    width: 100% !important;
}

.btn-submit-account.cancel:hover {
    background-color: #a32e21 !important;
}

@supports (scrollbar-width: thin) {
    :root {
        --scrollbar-width: 0px; /* For macOS and overlay scrollbars */
    }
}

@supports not (scrollbar-width: thin) {
    :root {
        --scrollbar-width: 16px; /* For Windows and classic scrollbars */
    }
}

/* Apply dynamic width and positioning */
.content-collapsed {
    left: var(--content-width-offset);
    width: calc(100vw - var(--content-width-offset));
}

/* Apply dynamic width and positioning */
.content-mobile-collapsed {
    left: 0 !important;
    width: 100vw !important;
}

.content-expanded {
    margin-left: 0px !important;
    width: 100vw !important;
}

.toggle-button-navbar {
    height: 30px;
    width: 30px;
    padding: 10px;
    display: flex;
    text-align: center;
    background-color: white;
    margin: 0 20px;
    left: -20px;
    top: 0;
    position: fixed;
    align-content: center;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: var(--primary)
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-space-between {
    justify-content: space-between !important;
}
.justify-content-space-evenly {
    justify-content: space-evenly !important;
}

.justify-content-end {
    justify-content: end !important;
}

.navbar-collapsed > span > i {
    display: block
}

form {
    margin: 0 !important;
}

.align-center-center {

    display: flex;
    align-content: center;
    justify-content: center;
    height: calc(100vh - 80px);
    align-items: center;
    flex-direction: column;
}

.new-menu {
    color: white;
    padding: 5px;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 0.75rem;
    font-weight: 600;
    display: inline-block;
    text-align: center;
}

.input-base {
    border: 2px solid var(--third);
    border-radius: 5px;
    text-align: center;
}

.main-searchbar {
    background-color: white;
    z-index: 1;
    transition: width .3s, height 0.6s ease-in-out;
}

.main-searchbar-container-full {
    background-color: white;
    width: calc(100%);
    position: fixed;
    align-items: center; /* Center content vertically */
    padding: 20px 60px;
    margin-left: 0px;
    z-index: 1
}

.main-searchbar-container-contained {
    width: calc(100% - 340px);
    position: fixed;
    align-items: center; /* Center content vertically */
    padding: 20px 60px;
    left: 380px;
    z-index: 1
}

div.searchbar-results > div > ul > div > div > a, div.searchbar-results > div > ul > div > a {
    text-decoration: none !important;
}

.input-search {

    align-items: center;
    background-color: #f1f1f1;
    border-radius: 25px;
    display: flex;
    padding: 10px 15px;
    transition: width .3s;
    width: 200px;
}

.main-searchbar-content {

    width: calc(100% - 420px);
    background-color: aliceblue;
    position: fixed;
    display: grid;
    align-items: center;
    padding: 20px;
    z-index: 10;
    top: 0;
    box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.15);

}

.searchbar-results {
    z-index: 1;
    min-width: 430px;
    max-width: 700px;
    background-color: #fefefe;
    position: fixed;
    margin-left: 440px;
    top: 100px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.input-search:focus-within {
    width: 400px; /* Expands to 400px when input is focused */
    transition: background-color .3s, width .3s;
    background-color: #d5d5d5;
}

.main-searchbar input:focus {
    outline: none !important;
    box-shadow: none !important;
}

.search-icon {
    font-size: 18px;
    margin-right: 10px; /* Space between icon and input */
    color: #555; /* Adjust icon color */
}

.search-input {
    border: none;
    background: transparent;
    outline: none;
    width: 100%; /* Allow the input to grow and fill available space */
    font-size: 16px;
    color: #333;
}

.search-input::placeholder {
    color: #999;
    font-style: italic;
}

.p-5-10 {
    padding: 5px 10px !important;
}

.mx-10 {
    margin: 0 10px !important;
}
.mx-20 {
    margin: 0 20px !important;
}

.flexwrap-wrap {
    flex-wrap: wrap;
}

.border-radius-round {
    border-radius: 5000px !important;
}

.border-solid-2px {
    border: solid 2px;
}

.border-solid-1px {
    border: solid 1px;
}

.no-border-top {
    border-top: unset !important;
}

.h-400{
    height: 400px!important;
}

.user-pp {
    color: var(--third);
    border: solid var(--third) 2px;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    display: flex;
    font-weight: 600;
    text-decoration: none !important;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;

}

.color-orangered {
    color: orangered;
}

.color-black-600 {
    color: black;
    font-weight: 600;
}

.archived-span {

    display: flex;
    align-items: center;
    border-radius: 25px;
    font-size: 0.8rem;
    font-weight: 800;
    background-color: orangered;
    color: white;
    padding: 5px 10px;
}

.finished-span {
    display: flex;
    align-items: center;
    border-radius: 25px;
    font-size: 0.8rem;
    font-weight: 800;
    background-color: #0bbe0b;
    color: white;
    padding: 5px 10px;
}

.strong {
    font-weight: 600 !important;
}

.button-third {
    background-color: var(--third);
    padding: 10px 15px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.button-third:hover {
    color: var(--third)!important;
    background-color: var(--secondary)!important;
    transition: background-color .3s, color .3s;
}

.bg-fifth {
    color: white;
    background-color: rgb(11 14 95) !important;
}

.btn-save:hover {
    background-color: rgb(11 14 95) !important;
    transition: .3s;
}

