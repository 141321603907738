.report-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.resume {
    display: flex;
    align-items: center;
}

.reporting-icon {
    font-size: 1.8rem;
    margin-right: 20px;
}

.parent > .blocs-2 > div:hover {
    cursor: pointer;
    background-color: rgb(0,0,0, .01);
    transition: background-color .5s;
}

.text-container {
    flex-direction: column;
    text-align: center;
    font-family: 'Montserrat Black', Montserrat, sans-serif;
}

.data {
    font-size: 1.2rem;
}

.header {
    color: var(--primary-color);
}

.text-secondary {
    color: var(--secondary-color);
}

.m-5 {
    margin: 5px;
}

.m-10 {
    margin: 10px;
}

.text-black {
    color: black!important;
}

.bg-orange{
    background-color: orange!important;
}

.align-center {
    align-items: center;
}

.column {
    flex-direction: column;
}
