.flex-container-calendar {
    display: grid;
    height: 100vh;
    background-color: #f5f5f5;
}

.user-list {
    display: flex;
    padding: 10px;
    background-color: #2c3e50;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    color: #ecf0f1;
}

.user-list select {
    padding: 10px;
    border: 1px solid #34495e;
    border-radius: 5px;
    background-color: #34495e;
    color: #ecf0f1;
    font-size: 1em;
}

.task-list {
    margin: 20px 10px 20px 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: #ecf0f1;
    border-radius: 8px;
    box-shadow: 0 0 5px 10px #f1f1f1;
    overflow-y: auto;
}

.task-card {
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    box-shadow: 0 0 5px 10px #f1f1f1;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: grab;
}

.task-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 5px 10px #f1f1f1;
}

.task-card h4 {
    margin: 0;
    font-size: 1.2em;
    color: #2c3e50;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.task-card p {
    margin: 0;
    font-size: 0.9em;
    color: #7f8c8d;
}

.calendar {
    padding: 20px;
    flex: 1;
    height: calc(100vh - 170px);
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    margin: 0 20px 20px 0;
    box-shadow: 0 0 5px 10px #f1f1f1;
}

#root > div > div > div.main-content.content-collapsed > div > div > div > div > div.display-grid > div.user-list {
    margin: 0 10px 0 20px;
    border-radius: 10px
}

.rbc-calendar {
    height: calc(100vh - 150px) !important;
}