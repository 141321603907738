/* SEOChecker.css */


/* Form */
.seo-checker-form {
    display: flex;
    align-items: center;
}

.seo-input {
    height: 38px;
    flex: 2;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
}

.seo-input:focus {
    border-color: #007bff;
    outline: none;
}

.seo-btn {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.seo-btn:hover {
    background-color: #0056b3;
}

/* Loading and error messages */
.seo-loading {
    margin: 10px 0;
}

.seo-error {
    color: red;
    font-weight: bold;
}

.seo-result-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* SEO data */
.seo-data {
    margin-top: 20px;
}

.seo-table {
    width: 100%;
    border-collapse: collapse;
}

.seo-table td {
    padding: 8px;
    border: 1px solid #ddd;
}

.seo-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.seo-table tr:hover {
    background-color: #f1f1f1;
}

.seo-advice-title {
    margin-top: 20px;
    color: #333333;
}

.seo-advice-list {
    list-style-type: disc;
    padding-left: 20px;
}

.seo-advice-item {
    margin: 5px 0;
}

/* Responsive design */
@media (max-width: 600px) {
    .seo-search-container {
        padding: 15px;
    }

    .seo-input, .seo-btn {
        width: 100%;
        margin: 5px 0;
    }

    .seo-checker-form {
        flex-direction: column;
    }
}

.seo-donut-chart-container {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto 20px;
}

.seo-donut-chart-score {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: #333;
}