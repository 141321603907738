/* New container class */
.container-update {
    padding: 20px;
    margin-top: 20px;
    background-color: var(--third);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
}

/* New individual item class */
.update-item {
    padding: 15px 20px;
    background-color: white;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.update-item:hover {
    background-color: var(--primary);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Specific classes for each item (for customization if needed) */
.update-customers {}
.update-orders {}
.update-projects {}
.update-products {}
/* Loading container */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Spinner */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: var(--primary);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

/* Loading text */
.loading-container p {
    margin-top: 10px;
    color: var(--primary);
    font-size: 14px;
}

/* Spinner animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
