.display-qr{
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btn-qr{
    cursor: pointer;
    flex: 1 1;
    height: 36px !important;
    background-color: white;
}


.btn-qr:hover {
    color: black;
    background-color: var(--forth);
    transition: background-color .3s;
}

.onglet-qr{
    padding: 10px;
    background-color: var(--forth);
    border-radius: 5px 5px 0 0;
    margin-right: 5px;
}

.onglet-qr:hover{
    background-color: var(--primary);
    transition: background-color .3s;
}

.search-bar-qr {
    width: auto;
    background-color: var(--primary);
    padding: 15px;
    border-radius: 0 5px 5px 5px;
    margin-bottom: 20px;
    box-shadow: 0 0 20px rgb(0, 0, 0, .2);
}

.qr-active-tab{
    background-color: var(--primary) ;
}

.label-qr {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
}

.qr-btns {

    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
}