.back-button:hover {
    background-color: var(--secondary);
    transition: background-color .3s;
}

.back-button {
    padding: 10px;
    background-color: var(--primary);
    border-radius: 5px;
    left: 0px;
    color: white;
    text-decoration: none;
}


/* Slider container */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}
/* The slider */
.slider.console {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: orangered;
    transition: 0.4s;
    border-radius: 34px;
}

/* The round slider ball */
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

/* When the checkbox is checked */
input:checked + .slider {
    background-color: var(--primary);
}

/* Move the slider ball when checked */
input:checked + .slider:before {
    transform: translateX(26px);
}

.client-no-client {
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* la */

.section {
    margin-bottom: 30px;
}

.section-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.form-group {
    margin-bottom: 15px;
}

.form-label {
    font-weight: bold;
}

.form-input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    box-sizing: border-box;
}

.option-group {
    display: flex;
    flex-wrap: wrap;
}

.option-group div {
    width: 45%;
    margin-right: 5%;
    margin-bottom: 15px;
}

.option-group div:last-child {
    margin-right: 0;
}

.form-input-small {
    width: calc(50% - 10px);
    margin-right: 10px;
    display: inline-block;
}

.form-input-small:last-child {
    margin-right: 0;
}

.btn-avis-container {
    display: flex;
    flex-direction: row;
    gap: 18px
}

@media screen and (max-width: 1600px) {
    .client-infos-container {
        flex-direction: column;
    }

    .client-compo-left {
        border: solid 1px rgb(222, 222, 222);
    }

    .text-center.input-base {
        width: 100%
    }

    .btn-avis-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .btn-avis-container-global {
        flex-direction: column;
    }

    .btn-avis-container {
        margin-top: 20px !important;
    }
}


@media screen and (max-width: 1450px) {
    .parent.second-parent-container-reporting.mb-20{
        flex-direction: column;
    }
}

@media screen and (min-width: 1450px) {
    .btn-avis-container-global {
        flex-direction: row;
    }

    .position-container {
        flex-direction: column-reverse;
    }

    .position-container.left{
        min-width: 400px;
    }
    .position-container.left > div   {
        width: 100%!important;
    }


    .btn-avis-container {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
}

.client-radio-input {
    /* Hide default styling */

    /* Custom styling */
    border: 1px solid lightgrey;
    border-radius: 4px; /* Small border radius */
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.client-radio-input:checked {
    background-color: lightblue; /* Light blue background when selected */
}

.client-radio-label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    border: solid 1px lightblue;
    border-radius: 10px;
    height: 50px;
    padding: 15px;
    background-color: white;
}

.radio-active {
    border: solid 1px lightblue;
border-radius: 10px;
    height: 50px;
    padding: 15px;
    background-color: #add8e642;
}

.client-options {
    padding: 20px;
    border: solid 1px lightblue;
    border-radius: 10px;
    background-color: #add8e642;

}

.technique-option.active {
    background-color: rgba(97, 218, 251, 0.26);
}