header {

    display: flex;
    justify-content: center;
}

section {
    background-color: var(--primary);
}

.li-add-trello:hover{
    background-color: var(--primary);
    transition: background-color .5s;
}
.li-add-trello{
    padding:10px;
}

.save-trello{
    padding: 10px 20px;
    cursor: pointer;
}

.save-trello {
    background-color: var(--primary) ;padding: 10px 20px; cursor:pointer
}
.save-trello:hover{
    background-color: var(--secondary);
    transition: background-color .3s;
}

.cancel-trello{
    padding: 10px 20px;
    cursor: pointer;
}
.cancel-trello:hover{
    background-color: orangered;
    transition: background-color .3s;
}

.span-add-icon {
    bottom: 0;
    padding: 15px;
    background-color: white;
    border-radius: 50%;
    color: var(--primary);
    opacity: .9;
    position: absolute;
    right: 0;
}

.modal-planification-container{
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 0 5px 10px #f1f1f1;
}
.modal-planification{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.react-trello-lane{border-radius: 10px!important;}
.react-trello-board{background-color: #0B6074!important;height: calc(100vh - 120px)!important; border-radius: 20px}

.smooth-dnd-draggable-wrapper > section{
    margin: 15px!important;
    height: calc(100vh - 150px)!important;
    max-height: unset!important;
}

.display-buttons-trello {
    margin-top: 20px;
    gap:20px;
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: flex-end;
}

.input-trello-add {
    width: 100%; padding: 10px; margin: 10px 0; box-sizing: border-box; text-align: center;
}

.lane-drop-area {
    padding: 10px;
    min-height: 100px;
    border: 2px dashed #ccc;
}

.lane-drop-area.drag-over {
    background-color: #f0f0f0;
    border-color: #00f;
}

.list-add-items-trello {
    position: absolute;
    bottom: 40px;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 5px #ddd;
    width: 220px;
    z-index: 1000;
    padding:10px;
}


/* MODAL */
/* styles.css */
.modal {
    display: flex;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #F3F4F5;
    padding: 20px;
    height: 80vh;
    width: 80%;
    max-width: 600px;
    border-radius: 10px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.trello-modal-title{
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.close-trello-modal{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
}
.close-trello-modal:hover {
    background-color: #E7E8EB;
    border-radius: 50%;
    transition: background-color .3s;
}

.trello-modal-title > h2, .trello-modal-title > i {
    margin: 0;
}

.trello-modal-title > div > i {
    display: flex;
    width: 30px;
    justify-content: center;
    flex-direction: column;
}

.modal-content > div {
    margin-bottom: 20px;
}

.line-trello {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

.line-trello > i {
    width: 30px;
    height: 20px;
    display: flex;
    align-items: center;
}