.warning {
    background-color: orangered;
}

.danger {
    background-color: red;
}
.success {
    background-color: #37f137;
}

.success > span {color: black}
.success > span > i {color: var(--primary)}

.info {
    background-color: #61dafb;
    color: black!important;
}

.info > span > i {color:#61dafb}

.info-top-bar{
    gap:20px;
    display: flex;
    padding: 20px 20px 20px 20px;
    color: white;
    position: absolute;
    width: calc(100% - 420px);
    left: 0px;
    z-index: 9;
    left: 380px;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    top:90;
}

.close-info{
    padding: 10px;
    background-color: white;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-info:hover{
    box-shadow: 0 0 5px 10px #f1f1f1;
    transition: box-shadow .3s;
}