
#chart {
    max-width: 100%;
    height: auto;
}

/* Styling for the summary list */
.summary-list {
    list-style-type: none;
    padding: 0;
}

.summary-list li {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.summary-list strong {
    color: #333;
}