.input-account {
    background-color: #f4f4f4;
    min-height: 40px;
    border-radius: 10px;
    border: none;
    padding-left: 15px;
    width: 100%;
    font-weight: 600;

}

.label-account {
    font-weight: 600;
}

.small-description-account {
    margin-top: 10px;
    font-size: .8rem;
    color: white;
}

.small-description-account.show {
    color: #666;
}

.btn-submit-account {
    cursor: pointer;
    display: flex;
    background-color: var(--third);
    min-height: 20px;
    border-radius: 5px;
    padding: 10px 20px;
    color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    font-weight: 600;
    font-size: .9rem;
    align-items: center;
    margin-top: 0!important;
    height: 40px;
}

.btn-submit-account:hover {
    background-color: var(--secondary);
    transition: background-color .5s;
}

.spans-account {

    justify-content: space-between;
    align-items: flex-end;
}

.modal-account-container > ul{
    display: grid;
    padding:0!important;
    margin: 0!important;
}

.modal-account-container{
    margin-right: 10px;
    position: absolute;
    padding: 15px;
    background-color: #fefefe;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    width: 200px;
    right: 40px;
    top:100px;
    z-index: 2;
}

.logout-container {
    cursor: pointer;
    padding: 5px 10px;
    color: white!important;
    background-color: red;
    border-radius: 5px;
    transition: background-color .3s;
}

.logout-container:hover{
    background-color: #c8410f;
    transition: background-color .5s;
}