
.email-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.email-table th, .email-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.email-table th {
    background-color: #f2f2f2;
    text-align: left;
}

.email-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.email-table tr:hover {
    background-color: #ddd;
}

.email-table ul {
    list-style-type: none;
    padding-left: 0;
}

.email-table li {
    padding: 2px 0;
}
