:root {
    --spacing: calc(1% + 10px)
}

.grid-container {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing);
    margin-bottom: var(--spacing);
}

.grid-container > div {


}

.grid-container > div > i {
    font-size: 1.5rem;
}

.grid-container > .resume {
    font-family: "Montserrat Black", "Montserrat", sans-serif;
}

.input-time-div {
    color: var(--secondary);
    font-weight: 600;
    padding: 10px !important;
    background-color: white;
    box-shadow: 0 0 5px 10px #f1f1f1;
    border-radius: 10px;
    margin-bottom: 20px;
}

.parent > .blocs-2 > div, .report-chart {
    color: var(--secondary);
    font-weight: 600;
    border-radius: 10px;
    flex-grow: 1;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column;
    background-color: rgb(249, 250, 251);
    border: 1px solid #ccc;
}


.blocs-2 {
    display: flex;
    flex-direction: row;
    gap: 20px;
}


.parent {
    gap: 20px;
}

@media screen and (max-width: 980px) {

    .blocs-2 {
        flex-direction: column
    }

}

.reporting-chart {
    background-color: white;
    margin-top: 20px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 6px #0000001a;
}

.calendar-reporting {
    top: 0;
    box-shadow: 0 0 5px 10px #f1f1f1;
    padding: 10px;
    position: absolute;
    right: 0;
}

.info-bulle {
    font-size: 0.8rem;
    padding: 10px;
    background-color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    color: white;
}

.vertical-separator {
    display: flex;
    flex-direction: column; /* Aligns items vertically */
    border-left: 2px solid #ddd;
}

.parent {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.resume .text-container {

}

.parent > .flex-1 {
    display: flex;
    background-color: white;
    border-radius: 5px;
    border: solid 1px #ccc;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    flex: 1 1 calc(100% / 7); /* Default to evenly distributed in one row */
}

@media screen and (max-width: 1530px) {
    .parent {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .parent > .flex-1 {
        flex: 1 1 calc(50% - 20px); /* 2 per row */
    }


    .input-container.department{
        z-index: 11!important;
        width: 100%;
    }
}

.reporting-card {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.resume {
    width: 100%; /* Ensures the content takes full width */
}

.text-container {
    width: 100%;
}

.separator {
    width: 100%; /* Full width of the parent */
    height: 1px;
    background-color: #dedede; /* Adjust color as needed */
}
.h-separator {
    height: 100%; /* Full width of the parent */
    width: 1px;
    background-color: #dedede; /* Adjust color as needed */
}

.title-label-reporting {
    text-align: center;
}

.header {
    text-align: center;
}