/* Basic resets */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    font-family: 'Inter', sans-serif;
    background-color: #f5f6f7;
    height: 100%;
}

/* Container for entire dashboard layout */
.md-dashboard-container {
    display: flex;
    height: calc(100vh - 130px);
    gap: 20px;
}

/* SIDEBAR */
.md-sidebar {
    width: 220px;
    background-color: #ffffff!important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px;
}

.md-sidebar-top .md-brand {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.md-nav-links ul {
    list-style: none;
}

.md-nav-links a {
    text-decoration: none;
    color: #555;
    font-size: 0.95rem;
    transition: color 0.2s;
}

.md-nav-links a:hover {
    color: #000;
}

.md-nav-links .md-active {
    color: #000;
    font-weight: 700;
    background-color:#e6f0ff!important; ;
}
.md-sidebar{
    background-color: #fff !important;
}
.md-active > .nav-link {
    background-color: #e6f0ff;
    border-radius: 10px;
    font-weight: 700!important;
}

.md-nav-links .nav-link {
    margin-bottom: 4px!important;

    font-weight: 100;
}

.md-sidebar-bottom {
    border-top: 1px solid #e5e5e5;
    padding-top: 1.5rem;
}

.md-help-section p {
    font-size: 0.9rem;
    color: #888;
    margin-bottom: 1rem;
}

/* Toggle switch for Light/Dark theme (just a placeholder) */
.md-theme-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
}

.md-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.md-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.md-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
}

.md-slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .md-slider {
    background-color: #4caf50;
}

input:checked + .md-slider:before {
    transform: translateX(20px);
}

.md-round {
    border-radius: 34px;
}

/* MAIN CONTENT */
.md-main-content {
    border-radius: 10px;
    background-color: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* TOP BAR */
.md-top-bar {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #e5e5e5;
    justify-content: space-between;
}

.md-search-bar input {
    width: 300px;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.md-create-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.95rem;
}

.md-create-btn:hover {
    background-color: #0056b3;
}

/* CONTENT LAYOUT (User list + Detail panel) */
.md-content-layout {
    display: flex;
    flex: 1;
    overflow-y: scroll;
}

/* USER LIST */
.md-user-list {
    width: 300px;
    background-color: #fff;
    border-right: 1px solid #e5e5e5;
    overflow-y: auto;
    padding: 20px;
    border-radius: 00px 0 0 20px;
}

.md-user-list h2 {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #444;
}

.md-user-list ul {
    list-style: none;
}

.md-user-list li {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 4px;
    transition: background-color 0.2s;
}

.md-user-list li:hover, .md-nav-links h4:hover {
    background-color: #f2f2f2;
    color: black;
}

.md-user-selected {
    background-color: #e6f0ff;
}
.md-user-selected-business {
    background-color: rgba(246, 246, 107, 0.51);
}

.md-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.md-user-info .md-user-name {
    font-weight: 500;
    color: #333;
}

.md-user-info .md-user-username {
    font-size: 0.85rem;
    color: #888;
}

/* USER DETAIL */
.md-user-detail {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.md-detail-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.md-detail-avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 16px;
}

.md-detail-user-info h3 {
    margin-bottom: 4px;
    font-size: 1.1rem;
    color: #333;
}

.md-detail-username {
    font-size: 0.9rem;
    color: #888;
}

.md-detail-actions {
    margin-left: auto;
    display: flex;
    gap: 8px;
}

.md-action-btn {
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
}

.md-follow {
    background-color: #5cb85c;
    color: #fff;
}

.md-message {
    background-color: #0275d8;
    color: #fff;
}

.md-detail-body {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 20px;
}

.md-private-note label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
}

.md-private-note textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 8px;
    resize: none;
}

.md-product-history {
    margin-top: 20px;
}

.md-product-history h4 {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #333;
}

.md-product-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.md-product-details {
    display: flex;
    align-items: center;
}

.md-product-img {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    object-fit: cover;
    margin-right: 12px;
}

.md-product-name {
    font-size: 0.95rem;
    font-weight: 500;
    color: #333;
}

.md-product-price {
    font-size: 0.85rem;
    color: #555;
}

.md-product-date {
    font-size: 0.85rem;
    color: #999;
}

/* Main table container */
.md-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f9f9f9; /* Very light grey background */
    color: #333;
}

/* Table header styling */
.md-table-head {
    background-color: #e0e0e0; /* Slightly darker grey for header */
}

.md-table-header {
    padding: 12px 15px;
    text-align: left;
    font-weight: 600;
}

/* Table body cells */
.md-table-cell {
    padding: 20px;
}

/* Alternate row colors */
.md-table-body .md-table-row:nth-child(even) {
    background-color: #f2f2f2;
}

/* Hover effect for rows */
.md-table-body .md-table-row:hover {
    background-color: #dcdcdc;
}
