.add-new-card:hover {
    filter: brightness(75%);
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.add-new-card {
    background-color: transparent!important;
    padding: 10px;
}