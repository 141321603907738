/* In your CSS file */
.datepicker-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px
}
.react-datepicker__header, .react-datepicker__header{
    border-radius: 10px 10px 0 0;
    background-color: var(--primary);
}
.react-datepicker {
    border-radius: 10px
}

.react-datepicker__navigation-icon {
    background-color: white
}

.react-datepicker {
    font-family: unset !important;
    border: 1px solid var(--primary);
}

.react-datepicker__navigation-icon::before {
    border-color: white;
}

.react-datepicker__day--in-range {
    background-color: var(--primary) !important;
    font-weight: 700
}

. {
    border-radius: 100px;
}

{
    border-top-right-radius: 10px
}

.chart-container, .filter-options {

    box-shadow: 0 0 10px  #f1f1f1;
}

.c3 text{font-family: "Montserrat", sans-serif; font-size: .7rem}
.c3{width: 100%}
.title-label-reporting{font-weight: 600!important; font-family: "Montserrat", sans-serif}

.w-250{width: 250px}
.filter-btn:hover,
.filter-btn-custom:hover{
    background-color: #eee;
    transition: background-color .2s;
}

.filter-btn{

    cursor: pointer;
    border-radius: 0;
    background-color: white;
    border: 1px solid #e3e3e3;
}

.filter-btn-custom{border-radius: 10px; border: 1px solid #e3e3e3;background-color: white ;
    cursor: pointer;   }
.filter-btn:first-child{border-radius: 10px 0 0 10px; border: 1px solid #e3e3e3; border-right: 0    }
.filter-btn:last-child{border-radius: 0px 10px 10px 0;border: 1px solid #e3e3e3; border-left: 0    }
.filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    margin: 0 auto;
    padding: 16px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px 10px #f1f1f1;
}

.filter-tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}

.filter-tab {
    padding: 10px 20px;
    background-color: #f5f5f5;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 14px;
}

.active-reporting {
    background-color: #ddd;
    border: 1px solid #efefef;
    color:black;
}
.active-reporting:hover {
    background-color: #ddd;
    color:black;
}

.date-picker-container {
    position: absolute;
    top: 140px;
    display: flex;
    width: calc(100% - 20px);
    justify-content: center;
    /* flex-direction: row; */
    flex-wrap: nowrap;
}

.date-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.today-btn,
.select-btn {
    padding: 8px 16px;
    background-color: #4a90e2;
    border: solid 1px var(--primary);
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.today-btn:hover,
.select-btn:hover {
    background-color: #357ABD;
}

.second-parent-container-reporting{flex-wrap: nowrap!important;}
.report-chart-wrapper {
    width: 100%;
}

.report-chart-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.report-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    margin-top: 20px;
}

.tr-Client{
    width:220px
}
.report-table thead {
    background-color: #f1f1f1;
}

.report-table th, .report-table td {
    border: 1px solid #e0e0e0;
    padding: 10px 15px;
    text-align: left;
}

.report-table th {
    font-weight: 600;
    color: #2c3e50;
}

.report-table td {
    color: #34495e;
}

.report-table td:nth-child(2) {
    font-weight: 500;
}

.report-table td:nth-child(3) {
    font-weight: 500;
}
