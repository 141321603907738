.ql-editor{
    min-height: 150px;
    height: unset!important;
}
.ql-toolbar .ql-snow {
    border-radius: 8px 8px 0 0!important;
}

.input-ticket {
    border-radius: 8px!important;
    padding:15px!important;
}

.ticket-span-alias {
    font-family: Avenir;
    padding:5px;
    border-radius: 8px;
    font-size: 1rem;
    color: #000;

}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.tickets-table {
    width: 100%;
    border-collapse: collapse;
}

.tickets-table th,
.tickets-table td {
    padding: 10px 5px;
    text-align: left;
}

.tickets-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.tickets-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.tickets-table tr:hover {
    background-color: #f1f1f1;
}

.priority-ticket {
    border: solid 2px #dedede;
    cursor: pointer;
}

.tickets-categories {
    display: flex;
    flex-direction: column;
}

.tickets-categorie{
    padding: 20px 0;
    cursor: pointer;
}
.tickets-categorie:hover{
    padding: 20px 0;
    cursor: pointer;
    background-color: #dedede;
    border: solid 2px;
}

.priority-ticket:hover {
     background-color: #F9FAFB !important;
    transition: background-color .3s;
 }

.ticket-status {
    font-family: "Montserrat", sans-serif!important;
      text-align: center;
      padding: 5px 10px;
      font-size: 0.8rem!important;
      border-radius: 100px;
      font-weight: 600;
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      justify-content: center;
      transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth hover effects */
  }

.ticket-status:hover {
    background-color: #e0e7ff; /* Highlight background on hover */
}
.ticket-status-child {
    position: absolute;
    top: 100%; /* Position right below the hovered element */
    left: 0;   /* Align left edges */
    width: 100%; /* Make it the same width as the parent container */

    display: flex;
    flex-direction: column;
    z-index: 2;
    background-color: #f9fafb;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px;

    opacity: 0; /* Initially hidden */
    transform: translateY(-10px); /* Shift upward slightly */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth fade-in and slide-in */
}

.ticket-status-child.display-flex {
    opacity: 1; /* Make visible on hover */
    transform: translateY(0); /* Reset position */
}
.crop-text {
    white-space: nowrap;
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add "..." for clipped text */
    display: block; /* Ensure block or inline-block context */}

.ticket-status-child span:not(:first-child) {
    margin: 5px 0;
    text-align: center;
    padding: 5px 10px;
    font-size: 0.8rem;
    border-radius: 100px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease; /* Smooth hover on child items */
}

.ticket-status-Ouvert:hover {

    background-color: rgb(86, 133, 217);
}
.ticket-status-Ouvert {
    background-color: rgb(100, 156, 255);
    border: solid 2px rgb(38, 113, 246);
}
.ticket-status-Escaladé {
    background-color: violet;
    border: solid 2px #ab58ab;
}
.ticket-status-Escaladé:hover {
    background-color: #e375e3;
}

.ticket-status-Assigné {
    background-color: #f4f400;
    border: solid 2px #c9c903;
}
.ticket-status-Assigné:hover {
    background-color: #dede0a !important;
}
.ticket-status-Réouvert {
    background-color: brown;
    border: solid 2px #d23e3e;
}
.ticket-status-Réouvert:hover {
    background-color: #c94646;
}
.ticket-status-En\ att\.\ Client {
    border: solid 2px black!important;
}
.ticket-status-Clôturé {
    border: solid 2px black!important;
}

.ticket-status-child span:first-child {
margin-top: 0;
    margin-bottom: 5px;
}
.ticket-status-child span:last-child {
margin-top: 5px;
    margin-bottom: 0;
}

.ticket-status-child span:hover {
    background-color: #d1fae5; /* Highlight on hover */
    color: #000; /* Text color adjustment for visibility */
}

.margin-top-5{margin-top: 5px!important;}

.ticket-status-nouveau {
    background-color: #EAF0FF;
    border: solid 2px #649CFF;
    color: #649CFF;
    transition: background-color .3s;
}

.ticket-status-nouveau:hover {
    background-color: #c0d0f8;
    transition: background-color .3s;
}

.ticket-status-annulé {
    background-color: #FFECE6;
    border:solid 2px #FC7277;
    color: #FC7277;
    transition: background-color .3s;
}
.ticket-status-annulé:hover {
    background-color: #facabb;
    transition: background-color .3s;
}
.ticket-status-résolu {
    background-color: lightgreen;
    border: solid 2px green;
    color: green;
    transition: background-color .3s;
}
.ticket-status-résolu:hover {
    background-color: #65da65;
    transition: background-color .3s;
}
.ticket-status-en.cours {
    background-color: lightyellow;
    border: solid 2px #c5c505;
    color: #c5c505;
    transition: background-color .3s;
}
.ticket-status-en.cours:hover {
    background-color: #f3f3a3;
    transition: background-color .3s;
}

.ticket-content {
    display: flex;
    border-radius:20px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    height: 100%; /* hauteur calculée pour l'écran */
    position: relative; /* peut rester static si nécessaire */
}

.ticket-main {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    flex: 65%;
    overflow-y: auto; /* Défilement vertical */
    background-color: #fff; /* Assure un fond couvrant */
}

.reply-to-separator {
    color: blue;
    font-weight: 700;
    height: 20px;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
    padding: 0 20px;
}

.container-back-ticket {
    display: flex;
    flex-direction: column;
}

.back-ticket {
    display: flex;
    gap: 10px;
    max-width: 190px;
    align-items: baseline;
}
.ticket-meta {
    border-left: solid 1px #dedede;
    border-radius: 0 20px 20px 0;
    flex: 35%;
    background-color: #f9fafb;
    overflow-y: auto; /* Défilement vertical */
}

.ticket-details {
    border-bottom: solid 1px #dedede;
}

.ticket-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.ticket-description {
    margin-bottom: 20px;
}

.ticket-description h2, .ticket-resolution-notes h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #444;
}

.ticket-resolution-notes {

}

.ticket-meta h3 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #444;
}

.ticket-meta p {
    font-size: 14px;
    margin: 5px 0;
    color: #555;
}

.ticket-meta p strong {
    color: #333;
}
.ql-container {
    font-size: 16px!important;
    max-height:fit-content;
}

.ticket-description-container {

    display: flex
;
    flex-direction: row;
    align-items: flex-start;
}

.ticket-icon-people {
    border-radius: 100px;
    padding: 5px 10px;
    border: solid 2px #dedede;
    font-weight: 600;
}

.add-ticket-icon-people:hover {
    background-color: lightblue;
}
.ticket-icon-people-container {
    flex-wrap: wrap;
    margin: 20px 0;
    align-items: center;
    display: flex;
    gap: 8px; /* Adds space between usernames and the + icon */
}
.ticket-description .ql-disabled {

}
.ticket-icon-people {
    border-radius: 100px;
    background-color: white;
    border: solid 2px #dedede;
    font-weight: 600;
    position: relative;
    font-size: .8rem;
}

.add-user-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: .8rem;
    color: #007bff; /* Use a primary color for better visibility */
    font-weight: bold;
    transition: color 0.3s ease;
    padding: 0;
    height: 20px;
    width: 20px;
}

.delete-user-icon {
    position: absolute;
    font-size: .7rem;
    color: red;
    font-weight: bold;
    height: 20px;
    width: 20px;
    top: -5px;
    right: -5px;
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    border: solid 2px red;
    border-radius: 50%;
    background: white;
}

.add-user-icon:hover {
    color: #0056b3; /* Darker shade on hover */
}


/* Modal styling */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.modal-content h2 {
    margin-bottom: 20px;
}

.quill{

    padding-bottom: 20px;
}

.ticket-comment {

    border-radius: 10px;
    padding: 10px;
    background-color: #F9FAFB;
    margin: 20px 0;
    border: solid 1px #ccc;
}

.ticket-assigned-people{
    max-width: 200px;
    flex-wrap: wrap;
    min-height: 60px;
    align-content: center;
    justify-content: start;
    flex-direction: row;
}

.reinit-filters {
    background-color: #ff6d36!important;

}
.reinit-filters:hover {
    background-color: #cb572b!important;
}

.no-bg{
    background-color: unset!important;
    background: unset!important;
}

.tickets-table-container{
    overflow-y: scroll;
}

.ticket-page {
    height: calc(100vh - 120px);
}

@media screen and (min-width: 1500px) {
    .tickets-sidebar  {
        max-width: 350px;
        height: 100%;
        flex: auto;
    }

    .tickets-sidebar-collapsed  {
        max-width: 350px;
        height: 100%;
        width: 90px;
    }

    .tickets-tab {
        height: calc(100% - 50px);
    }

}

.tickets-sidebar-collapsed > div > div > span > i{
    margin-right: 0!important;
}
.tickets-sidebar-collapsed > div > div > span {
    display: flex;
    justify-content: center;
}


@media screen and (max-width: 1500px){

    .tickets-sidebar {
        flex: auto;
    }
    .tickets-tab {
        height: unset!important;
    }
    .ticket-meta{
        border-top: solid 1px #dedede;
        border-radius: 0 0 20px 20px;
        border-left: unset;
    }

    .ticket-content {
        flex-direction: column;
        height: unset;
    }
    .ticket-status-historic-container {
        display: flex;
        flex-direction: row;
    }

    .ticket-status-historic-span{
        width: auto;
    }

    .ticket-status-historic-icon{
        flex:unset!important;
    }

    .tickets-tab{
        flex-direction: column;

        height: calc(100% - 90px);
    }

    .sidebar-tickets {
        gap: 20px;
        display: flex;
        flex-direction: row!important;
        flex-wrap: wrap;
    }

    .sidebar-tickets > div {
        min-width: 180px;
    }

}

.span-ticket-people {
    font-size: .8rem;
    padding: 4px 8px;
    border: solid 2px rgb(29, 186, 176);
    color: rgb(29, 186, 176);
    border-radius: 50px;
    font-weight: bold;
    background: white;
    margin: 5px 10px;
}


.sidebar-tickets {

    gap: 20px;
    display: flex;
    flex-direction: column;
}






.ticket-comment {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.reply {
    margin:0 20px;
    background-color: darkgreen;
    color: white;
    width: 50%;
    border-radius: 5px;
}

.reply-button {
    font-size: 0.9rem!important;
    background-color: #fff!important;
    border: none;
    color: #007bff;
    cursor: pointer;
    display: flex;
    border-left: solid 1px #dedede;
    border-radius:0 0 10px;
    padding:10!important;
    align-items: center;
    justify-content: center;
    text-decoration: none!important;
    width: 50px;
}

.reply-button:hover {
    text-decoration: underline;
    background: #f8f8ff !important;
}

.ticket-\#333:hover {
    color:#333!important;
}

.ticket-brown:hover{color:brown!important;}
.css-zoadfa{text-align: left}

.css-3kd8s5-control, .css-13mvri2-control{height: 51px!important;border-radius: 8px!important;padding-left: 15px!important;}

.ql-toolbar{
    border-radius: 10px 10px 0 0 ;
}

.ql-container{border-radius: 0 0 10px 10px}

.quill {width: 100%}

.time-spent-form {
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    border: solid 2px #ddd;
}

.time-spent-form .form-group {
    margin-bottom: 15px;
}

.time-spent-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.time-spent-form input[type="time"],
.time-spent-form input[type="text"],
.time-spent-form textarea
{
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    max-width: 100%;
}

.time-spent-form .submit-btn {
    background-color: var(--third);
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.time-spent-form .submit-btn:hover {
    background-color: #0056b3;
}

.add-time-container {
    margin: 10px 0 20px 0;
}

strong.title-ticket {
    font-size: 17px!important;
}

.add-time-ticket:hover{
    background-color: #f8f8ff;
    transition: background-color .3s;
}
.bg-orangered:hover {
    background-color: #bf7c02!important;
    transition: background-color .3s;
}