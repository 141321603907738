.archived-div {
    margin-bottom: 10px;
    height: 70px;
    display: flex;
    background-color: rgba(255, 69, 0, 0.53);
    border-radius: 15px;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.finished-div {
    margin-bottom: 10px;
    height: 70px;
    display: flex;
    background-color: lightblue;
    border-radius: 15px;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    right: 0
}

.positions-list  a:first-child > div{
    margin-top: 0!important;
}

.button-action {
    margin-left: 0;
    border-radius: 0 5px 5px  0;
}

.button-action-save {

    border-radius: 5px;
}

.arrow-icon > i {
    transition: transform 0.5s ease;
}

.rotate {
    transform: rotate(180deg);
}

.title-positions-menus {

    width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    cursor: pointer;
}

div.last-tasks-container > div > ul {
    padding: 0;
}

.client-button {
    padding: 10px;
    background-color: var(--primary);
    border-radius: 5px;
    color: white;
}

.client-button:hover {
    background-color: var(--secondary);
    transition: background-color .3s;
}


@media screen and (max-width: 1450px) {
    .container-position{
        flex-direction: column-reverse;
        gap: 20px;
    }

    .position-container.left {
        padding-right: 0!important;
    }
}